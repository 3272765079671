import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import Container from '../components/Container'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'

const Index = () => {
  return (
    <Layout>
      <SEO />
        <Helmet>
          <title>{`${config.siteTitle}`}</title>
        </Helmet>
      <Container>
      </Container>
    </Layout>
  )
}

export default Index
